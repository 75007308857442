<template>
  <el-row style="margin-top: -10px" >
    <el-col>
      <el-row style="margin-bottom: 10px">
        <el-card class="box-card" style="width: 800px;">
          <el-button-group >
            <el-button type="primary" icon="el-icon-refresh"  size="medium" @click="refreshData">刷新数据</el-button>
            <el-button type="info" icon="el-icon-picture-outline-round"  size="medium" @click="queryData">数据用量</el-button>
            <el-button type="success" icon="el-icon-circle-plus"  size="medium" @click="DialogVisible = true">添加数据</el-button>
            <el-button type="danger" icon="el-icon-delete-solid"  size="medium" @click="clearData">清空全部</el-button>
          </el-button-group>
          <el-input
              placeholder="搜索的数据"
              v-model="pagParams.searchval"
              clearable
              style="height: 30px; width: 150px;margin-left: 10px;margin-right: 10px"
              prefix-icon="el-icon-view">
          </el-input>
          <el-button type="primary" icon="el-icon-search" round size="medium" @click="searchData">搜索</el-button>
        </el-card>
      </el-row>
      <el-popover
          placement="right"
          width="80%"
          trigger="manual"
          v-model="visibleLab">
        <el-table :data="labData" :row-class-name="tableRowClassName" v-loading="loadingLab" border>
          <el-table-column width="100" property="total" label="总数"></el-table-column>
          <el-table-column width="100" property="status_u" label="已使用"></el-table-column>
          <el-table-column width="100" property="status_0" label="未使用"></el-table-column>
          <el-table-column width="100" property="status_2" label="成功"></el-table-column>
          <el-table-column width="100" property="status_e" label="错误"></el-table-column>
          <el-table-column width="100" property="status_1" label="正在注册"></el-table-column>
        </el-table>
      </el-popover>
    </el-col>
    <el-col style="margin-top: -5px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pagParams.page"
          :page-sizes="[15, 50,100, 200, 500]"
          :page-size="this.pagParams.limit"
          :total="this.pagParams.total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-col>
    <el-table
        class="el-ta"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        stripe
        border
        size="mini"
        highlight-current-row
        highlight-selection-row
        :default-sort = "{prop: 'sort', order: 'ascending'}">

      <el-table-column
          type="selection"
          width="40px">
      </el-table-column>

      <!--      <el-table-column-->
      <!--          prop="sort"-->
      <!--          label="序号"-->
      <!--          width="80px"-->
      <!--          sortable-->
      <!--          show-overflow-tooltip>-->
      <!--        <template slot-scope="scope">{{ scope.$index+1 }}</template>-->
      <!--      </el-table-column>-->

      <el-table-column
          prop="data"
          label="号码"
          width="100px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          width="100px"
          sortable
          :filters="[
           { text: '未使用',value: 0 },
           { text: '正在注册', value: 1 },
           { text: '注册成功', value: 2 },
           { text: '辅助错误', value: 3 },
           { text: '人脸错误', value: 4 },
           { text: '短信验证', value: 5 },
           { text: '其他错误', value: 6 },]"
          :filter-method="filterStatus"
          filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
              :type=getMsgColor(scope.row.status)
              disable-transitions>{{getMsg(scope.row.status)}}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="use_time"
          label="使用时间"
          width="150px"
          sortable
          show-overflow-tooltip
          :formatter="formatDate"
      >
      </el-table-column>
      <el-table-column
          prop="msg"
          label="备注"
          width="150px"
          sortable
          show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
          prop="work"
          label="操作">
        <template slot-scope="scope">
          <el-button  icon="el-icon-edit"  size="mini" @click="_showChangDlg(scope.row)">修改</el-button>
          <el-button  icon="el-icon-error" size="mini" @click="delData(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog
        :visible.sync="DialogVisible"
        width="50%"
    >
      <el-form ref="form" :model="formAdd" label-width="20px">
        <el-form-item label="号码">
          <el-input type="textarea" :rows="20" v-model="tmp" style="width: 90%;margin-left: 10px;margin-right: 10px;margin-top: -10px"></el-input>
        </el-form-item>
        <el-form-item>
          <!--          <el-button @click="dialogVisible = false">取 消</el-button>-->
          <el-button type="primary" @click="_addData">确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog
        :visible.sync="dlgShow_Change"
        width="30%"
        title="修改信息"
    >
      <el-card class="box-card" style="margin-top: -20px">
        <el-form ref="formChange" :model="formChange" label-width="60px" >
          <el-form-item label="备注">
            <el-input v-model="formChange.msg" style="width: 100%;margin-right: 10px;margin-top: -10px" clearable></el-input>
          </el-form-item>

          <el-form-item label="状态">
            <el-select v-model="formChange.status" placeholder="设置状态反馈">
              <el-option
                  v-for="item in opStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="Number(item.value)">
              </el-option>

            </el-select>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" @click="postChange">确认修改</el-button>
          </el-form-item>

        </el-form>
      </el-card>

    </el-dialog>

  </el-row>

</template>

<script>


import {trim} from "core-js/internals/string-trim";

export default {

  data() {
    return {
      userName : window.sessionStorage.getItem('account'),
      tableData: [],
      multipleSelection: [],
      DialogVisible:false,
      dlgShow_Change:false,
      visibleLab : false,
      pagParams:{
        total:15,limit:15,page:1,searchval:''
      },
      formAdd: {
        data:[], status:0,
      },
      formChange:{
        id:null,
        status:null,
        msg:'',
        tmp:null
      },
      labData: [],
      file: null,
      tmp:'',
      loadingLab:true,
      opStatus:[
        { label:"未使用" , value:0},
        { label:"正在注册", value:1},
        { label:"注册成功", value:2},
        { label:"辅助错误", value:3},
        { label:"人脸错误", value:4},
        { label:"其他错误", value:5}
      ]
    }
  },
  created() {
    this.getData();
  },
  methods: {
    _showChangDlg(row){
      this.formChange.status = row.status;
      this.formChange.msg = row.msg;
      this.formChange.id = row.id;
      this.dlgShow_Change = true;
    },
    getMsgColor(status){
      let result = '';
      if(status === 0){
        result = 'info';
      }else if(status === 1){
        result = 'primary';
      }else if(status === 2){
        result = 'success';
      }else {
        result = 'danger';
      }
      // scope.row.status > 2 ? 'danger' : 'success'";
      return result;
    },
    getMsg(status){
      let result = '';
      if(status === 0){
        result = '未使用';
      }else if(status === 1){
        result = '正在注册';
      }else if(status === 2){
        result = '注册成功';
      }else if(status === 3){
        result = '辅助错误';
      }else if(status === 4){
        result = '人脸错误';
      }else if(status === 5){
        result = '短信验证';
      }else{
        result = '其他错误';
      }
      return result;
    },
    refreshData(){
      this.visibleLab = false;
      this.pagParams.page  = 1;
      // this.pagParams.limit = 5;
      // this.pagParams.total = 5;
      this.pagParams.searchval = '';
      this.getData();
    },

    searchData(){
      this.pagParams.page = 1;
      this.getData();
    },
    _addData() {
      if (!this.tmp ){
        this.$msgNot.error('数据不能为空');
        return;
      }

      this.DialogVisible = false
      this.addData();

    },
    async getData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      const {data:res} = await this.$ajax.post("/api/phone/getdata",{'account':this.userName,'data':this.pagParams});
      if (res.code === 200){
        this.tableData = res.data;
        this.pagParams.total = res.total;
      }else {
        this.$msgNot.error('获取数据失败:'+res.msg);
      }

    },
    async queryData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      this.visibleLab = !this.visibleLab;
      if(!this.visibleLab){
        return;
      }

      const {data:res} = await this.$ajax.post("/api/phone/querydata",{'account':this.userName});
      if (res.code === 200){
        this.labData = res.data;
        this.loadingLab = false;
      }else {
        this.$msgNot.error('查询用量失败:'+res.msg);
      }

    },
    async postChange(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      const {data:res} = await this.$ajax.post("/api/phone/changeData",{'account':this.userName,'data':this.formChange});
      if (res.code === 200){
        this.$msgNot.success('修改成功');
        this.formChange.status = null;
        this.formChange.msg = '';
        this.formChange.id = null;
        this.dlgShow_Change = false;
        await this.getData();
      }else {
        this.$msgNot.error('修改失败:'+res.msg);
      }

    },
    async addData(uID = ''){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }
      this.formAdd.data = trim(this.tmp).split('\n');
      const {data:res} = await this.$ajax.post("/api/phone/addData",{'account':this.userName,'data':this.formAdd,'uid':uID});
      if (res.code === 200){
        this.$msgNot.success('添加成功,数量:'+ this.formAdd.data.length);
        this.formAdd.data = [];
        this.formAdd.status = 0;
        this.tmp = '';
        await this.getData();
      }else {
        this.$msgNot.error('添加数据失败:'+res.msg);
      }

    },
    async delData(id){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      const {data:res} = await this.$ajax.post("/api/phone/deldata",{'account':this.userName,'id':id});
      if (res.code === 200){
        this.$msgNot.success('删除成功');
        await this.getData();
      }else {
        this.$msgNot.error('删除失败:'+res.msg);
      }

    },

    async clearData(){
      if (!this.userName) {
        this.$msgNot.error('登录状态异常,请重新登录');
        return;
      }

      await this.$msgBox.confirm('确认清空所有数据？','提示',{confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',})
          .then(async ()=>{
            const {data:res} = await this.$ajax.post("/api/phone/clearData",{'account':this.userName});
            if (res.code === 200){
              this.$msgNot.success('清空成功');
              await this.getData();
            }else {
              this.$msgNot.err('清空失败:'+res.msg);
            }

          }).catch(()=>{

          });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pagParams.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pagParams.page = val;
      this.getData();
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    filterFeedback(value, row) {
      return row.feedback === value;
    },
    handleChange(file) {
      this.file = file.raw;
    },
    tableRowClassName({row, rowIndex}) {
      return 'warning-row';
    },
    formatDate(row, column) {
      if(!row[column.property]) return '';
      let date = new Date(row[column.property] * 1000)
      const Y = date.getFullYear() + '-'
      const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
      const D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
      const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
      const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return Y + M + D + h + m + s
    }
  }

}
</script>

<style>

.el-ta {
  /*background-color: #222D32;*/
  color: #000000;
  text-align: left;
  /*line-height: 150px;*/
  margin: 1px;
  padding: 1px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.el-table .warning-row {
  background: #f0f9eb;
}


</style>